module.exports = function grayscale(color) {
  function gs() {
    /* jslint strict:false */
    var rgb = this.rgb();
    var val = rgb._red * 0.3 + rgb._green * 0.59 + rgb._blue * 0.11;

    return new color.RGB(val, val, val, rgb._alpha);
  }

  color.installMethod('greyscale', gs).installMethod('grayscale', gs);
};
