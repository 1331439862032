module.exports = function LAB(color) {
  color.use(require('./XYZ.js'));

  color.installColorSpace('LAB', ['l', 'a', 'b', 'alpha'], {
    fromRgb: function () {
      return this.xyz().lab();
    },

    rgb: function () {
      return this.xyz().rgb();
    },

    xyz: function () {
      // http://www.easyrgb.com/index.php?X=MATH&H=08#text8
      var convert = function (channel) {
        var pow = Math.pow(channel, 3);
        return pow > 0.008856 ? pow : (channel - 16 / 116) / 7.87;
      };
      var y = (this._l + 16) / 116;
      var x = this._a / 500 + y;
      var z = y - this._b / 200;

      return new color.XYZ(
        convert(x) * 95.047,
        convert(y) * 100.0,
        convert(z) * 108.883,
        this._alpha
      );
    },
  });
};
